export default {
  // inherits family:text classes
  // https://formkit.com/inputs/email#sections
  outer: '',
  wrapper: '',
  label: '',
  prefixIcon: '',
  suffixIcon: '',
  inner: 'flex rounded-2.25 bg-#eef7e7 justify-between items-center',
  input: 'w-8 grow text-4.5 font-500 text-center text-tekstovyy bg-transparent [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
  help: '',
  messages: '',
  message: '',
  decrement: 'size-7 flex items-center justify-center bg-firmennyy/20 disabled:bg-transparent leading-0.8 rounded-2.25 text-firmennyy text-[1.75rem]',
  increment: 'size-7 flex items-center justify-center bg-firmennyy/20 disabled:bg-transparent leading-0.8 rounded-2.25 text-firmennyy text-[1.75rem]'
};
