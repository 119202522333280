import { createInput } from '@formkit/vue';
import type { FormKitTypeDefinition } from '@formkit/core';

import Counter from './Counter.vue';
import Autocomplete from './Autocomplete.vue';
import Rating from './Rating.vue';
import SelectGroup from './SelectGroup.vue';

const counterDefinition: FormKitTypeDefinition = createInput(Counter, {
  props: ['max', 'min']
});

const autocompleteDefinition: FormKitTypeDefinition = createInput(Autocomplete, {
  props: ['options', 'dynamic'],
  family: 'text'
});

const ratingDefinition: FormKitTypeDefinition = createInput(Rating, {
  props: ['max']
});

const selectGroupDefinition: FormKitTypeDefinition = createInput(SelectGroup, {
  props: ['options']
});

export const inputs = {
  counter: counterDefinition,
  autocomplete: autocompleteDefinition,
  rating: ratingDefinition,
  selectGroup: selectGroupDefinition
};
