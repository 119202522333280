export default {
  outer: '',
  wrapper: 'flex flex-col items-start justify-start last:mb-0',
  label: '',
  prefixIcon: '',
  suffixIcon: '',
  inner: 'w-full relative',
  input: '',
  help: '',
  messages: '',
  message: '',
  selectGroupWrapper: 'flex rounded-2.5 bg-#f5f8f9',
  selectGroupItem: 'p-2 flex-1 rounded-2.5 text-seryy text-3.25 text-center data-[selected=true]:(text-white bg-firmennyy) hover:(text-white bg-firmennyy) transition-colors cursor-pointer',
  dropdownWrapper: 'rounded-4 p-2.5 shadow-[0_6px_18px_0] shadow-black/16 ring-1 ring-inset ring-#f1f1f1 absolute top-full translate-y-2 left-0 min-w-55 z-1 bg-white',
  listbox: 'max-h-50 overflow-y-auto custom-scroll w-full flex flex-col mt-2.5 pr-1',
  listItem: 'w-full p-2.5 text-center text-tekstovyy text-4 cursor-pointer hover:bg-#f5f8f9 rounded-2.5'
};
