<template>
  <button
    type="button"
    :class="context.classes.decrement"
    :disabled="context._value <= min"
    @click="decrement"
  >
    -
  </button>
  <span
    :class="context.classes.input"
  >{{ context.value }}</span>
  <button
    type="button"
    :class="context.classes.increment"
    :disabled="incrementDisabled"
    @click="increment"
  >
    +
  </button>
</template>

<script lang="ts" setup>
import type { FormKitFrameworkContext } from '@formkit/core';
const props = defineProps<{
	context: FormKitFrameworkContext & {
		max?: number,
		min?: number
	}
}>();

const max = computed(() => props.context?.max);
const min = computed(() => props.context?.min ?? 0);

const increment = () => {
  props.context.node.input(Number(props.context._value ?? 0) + 1);
};
const decrement = () => {
  props.context.node.input(Number(props.context._value ?? 0) - 1);
};

const incrementDisabled = computed(() => {
  if (!max.value) { return false; }
  return props.context._value >= max.value;
});
</script>
