export default {
  // inherits family:box classes
  // https://formkit.com/inputs/radio#sections
  outer: '',
  wrapper: '',
  fieldset: '',
  legend: '',
  help: '',
  inner: '',
  options: '',
  option: '',
  decorator: 'rounded-full',
  decoratorIcon: 'max-w-[50%]',
  label: '',
  optionHelp: '',
  messages: '',
  message: ''
};
