// formkit.config.js
import type { FormKitOptions } from '@formkit/core';
import type { FormKitInputs } from '@formkit/inputs';

import { bindings } from '@formkit/vue';
import { createValidationPlugin } from '@formkit/validation';
import { createI18nPlugin } from '@formkit/i18n';
import { inputs, theme, locales } from './formkit';
import { generateClasses, createThemePlugin } from '@formkit/themes';
import { createFloatingLabelsPlugin } from '@formkit/addons';
import { createLibraryPlugin, text, form, number, tel, password, email as emailInput, checkbox, group, radio, list, meta, file, textarea } from '@formkit/inputs';
import { required, email, length, contains_alpha, accepted, confirm, date_format } from '@formkit/rules';

const validation = createValidationPlugin({ required, email, length, contains_alpha, accepted, confirm, date_format });
const library = createLibraryPlugin({ ...inputs, tel, text, number, form, password, email: emailInput, checkbox, group, radio, list, meta, file, textarea });
const i18n = createI18nPlugin(locales);
const floatingLabels = createFloatingLabelsPlugin();

const themePlugin = createThemePlugin(undefined, {
  check: '<svg viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 10.0769L9.5625 17L24 2" stroke="CurrentColor" stroke-width="4.66667"/></svg>'
});

export default {
  plugins: [library, validation, i18n, bindings, floatingLabels, themePlugin],
  config: {
    classes: generateClasses(theme)
  }
} satisfies FormKitOptions;

declare module '@formkit/inputs' {
	interface FormKitInputProps<Props extends FormKitInputs<Props>> {
		'counter': {
			type: 'counter',
			max?: number,
			min?: number,
			value: number
		},
		'autocomplete': {
			type: 'autocomplete',
			value?: unknown,
			options: { label: string, value: unknown }[] | ((search: string) => Promise<{ label: string, value: unknown }[]>),
			dynamic?: { enabled: boolean, debounce?: number }
		}
		'rating': {
			type: 'rating',
			value?: number,
			max?: number
		},
		'selectGroup': {
			type: 'selectGroup',
			value?: unknown,
			options: { group: string, options: { label: string, value: unknown }[] }[]
		}
	}
}
