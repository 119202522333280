export default {
  outer: 'group min-w-0 data-[disabled]:select-none data-[disabled]:opacity-50 data-[disabled]:pointer-events-none',
  wrapper: '',
  label: 'block text-tekstovyy text-3.5 mb-3',
  legend: '',
  input: 'appearance-none outline-none placeholder:text-#AAB8C1 w-inherit',
  placeholder: 'text-#AAB8C1',
  prefixIcon: 'flex shrink-0 items-center mr-3.5 text-4.5 h-1em w-1em [&>svg]:w-full',
  suffixIcon: 'flex items-center ml-3.5 text-4.5 h-1em w-1em shrink-0 [&>svg]:w-full',
  loaderIcon: 'animate-spin flex items-center my-auto ml-3.5 text-4.5 h-1em w-1em shrink-0 [&>svg]:w-full',
  loadMoreInner: '',
  help: '',
  messages: 'text-#ed6a41 text-[13px] font-500 leading-0.8 text-center mt-1.5',
  message: '',
  overlay: '',
  overlayPlaceholder: '',
  overlayLiteral: '',
  overlayChar: '',
  overlayEnum: ''
};
