export default {
  /*
  number: https://formkit.com/inputs/number#sections
  search: https://formkit.com/inputs/search#sections
  tel: https://formkit.com/inputs/tel#sections
  text: https://formkit.com/inputs/text#sections
  */

  outer: '',
  wrapper: 'flex flex-col items-start justify-start last:mb-0',
  label: '',
  prefixIcon: '',
  suffixIcon: '',
  inner: 'w-full py-2.75 px-3.5 rounded-3 border-1 border-border flex items-center group-data-[disabled]:cursor-not-allowed',
  input: 'text-4.25  text-tekstovyy min-h-4.25 bg-transparent grow border-none p-0 focus:ring-0',
  help: '',
  messages: '',
  message: ''
};
