<template>
  <input
    v-bind="context.attrs"
    :id="context.id"
    ref="inputEl"
    v-model="input"
    :data-expanded="isShow"
    type="text"
    :disabled="(context.disabled as boolean)"
    :class="context.classes.input"
    :name="context.node.name"
    @focus="isShow = true, isFocused = true"
  >
  <div
    v-show="isShow"
    ref="dropdownWrapperEl"
    :class="context.classes.dropdownWrapper"
  >
    <ul
      v-if="options?.length"
      :class="context.classes.listBox"
    >
      <li
        v-for="(option, i) in options"
        :key="i"
        :class="context.classes.listItem"
        @click="clickOnItem(option)"
      >
        {{ option.label }}
      </li>
    </ul>
    <span
      v-if="statusLabel"
      :class="context.classes.statusLabel"
    >{{ statusLabel }}</span>
  </div>
</template>

<script lang="ts" setup>
import type { FormKitFrameworkContext } from '@formkit/core';

const props = defineProps<{
	context: FormKitFrameworkContext & {
		options: { label: string, value: unknown }[] |((search: string) => Promise<{ label: string, value: unknown }[]>),
		dynamic?: { debounce?: number, enabled: boolean }
	}
}>();

const isShow = ref(false);
const isFocused = ref(false);

let lastInput: { value: unknown, isSelected: boolean } = { value: null, isSelected: false };

const inputEl = ref(null);
const dropdownWrapperEl = ref(null);

onClickOutside(inputEl, (event) => {
  if (isFocused.value) {
    isShow.value = false;
    if (!lastInput.isSelected) { input.value = lastInput.value; }
    props.context.handlers.blur();
  }
}, { ignore: [dropdownWrapperEl] });

function clickOnItem (option: any) {
  props.context.node.input(option.value);
  input.value = option.label;
  lastInput = { isSelected: true, value: option.label };
  isShow.value = false;
}

const input = ref();

watch(input, () => {
  lastInput.isSelected = false;
});

const options = ref<{ label: string, value: unknown }[]>();

const status = ref<'pending' | 'idle' | 'success' | 'error'>('idle');

if (props.context?.dynamic?.enabled) {
  watchDebounced(input, async (newValue) => {
    if (typeof props.context.options === 'function') {
      status.value = 'pending';
      options.value = await props.context.options(newValue);
      if (!options.value) { return status.value = 'error'; }
      status.value = 'success';
    }
  }, { debounce: props.context.dynamic?.debounce ?? 300 });
} else {
  status.value = 'idle';
  watch(() => props.context.options, (newValue) => {
    options.value = newValue;
  });
}

const statusLabel = computed(() => {
  if (status.value === 'success' && !options.value?.length) { return 'Ничего не найдено'; }
  if (status.value === 'error') { return 'При загрузке данных произошла ошибка'; }
  if (status.value === 'pending') { return 'Загрузка данных'; }
  return null;
});
</script>
