export default {
  // inherits family:box classes
  // https://formkit.com/inputs/checkbox#sections
  outer: '',
  wrapper: '',
  fieldset: '',
  legend: '',
  help: '',
  inner: '',
  options: '',
  option: '',
  decorator: 'rounded-1',
  decoratorIcon: 'i-custom:checkmark',
  label: '',
  optionHelp: '',
  messages: '',
  message: ''
};
