export default {
  /*
  checkbox: https://formkit.com/inputs/checkbox#sections
  radio: https://formkit.com/inputs/radio#sections
  */
  outer: '',
  wrapper: 'inline-flex items-center',
  fieldset: '',
  legend: '',
  help: '',
  inner: '',
  input: 'peer absolute h-0 w-0 overflow-hidden opacity-0',
  decorator: 'relative block select-none w-1em aspect-square ring-0.75 ring-inset ring-border mr-2 text-transparent peer-checked:text-white peer-checked:bg-firmennyy peer-checked:ring-white/30 peer-disabled:cursor-not-allowed',
  decoratorIcon: 'absolute left-1/2 top-1/2 flex size-2.25 -translate-x-1/2 -translate-y-1/2',
  options: '',
  option: 'mb-2 last:mb-0 data-[disabled]:opacity-50 group-data-[disabled]:data-[disabled]:opacity-100',
  label: '$reset block mb-0 text-3 text-seryy/70',
  optionHelp: '',
  messages: '',
  message: ''
};
