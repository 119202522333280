<template>
  <div :class="context.classes.itemsWrapper">
    <div
      v-for="star in max"
      :key="star"
      :class="context.classes.items"
      @click="handleInput(star)"
      @mouseover="hoverStarIndex = star"
      @mouseleave="hoverStarIndex = 0"
    >
      <div :class="context.classes.iconWrapper">
        <span :class="[context.classes.icon, getIcon(star)]"></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { FormKitFrameworkContext } from '@formkit/core';
const props = defineProps<{
	context: FormKitFrameworkContext & {
		max?: number,
	}
}>();

const max = computed(() => props.context.max ?? 5);

function handleInput (star: number) {
  props.context.node.input(star);
}

const hoverStarIndex = ref(0);

const getIcon = (star:number) => {
  if (hoverStarIndex.value != 0 && star > hoverStarIndex.value) { return props.context.classes.iconInactive; }
  if (props.context.value >= star) { return props.context.classes.iconActive; }
  if (star <= hoverStarIndex.value) { return props.context.classes.iconHover; }
  return props.context.classes.iconInactive;
};

</script>
