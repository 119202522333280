export default {
  // inherits family:text classes
  // https://formkit.com/inputs/tel#sections
  outer: '',
  wrapper: '',
  label: '',
  prefixIcon: '',
  suffixIcon: '',
  inner: '',
  input: '',
  help: '',
  messages: '',
  message: ''
};
