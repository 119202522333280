import global from './global';
// Core input imports
import familyBox from './families/box';
import familyText from './families/text';
import checkbox from './inputs/checkbox';
import counter from './inputs/counter';
import form from './inputs/form';
import radio from './inputs/radio';
import search from './inputs/search';
import tel from './inputs/tel';
import text from './inputs/text';
import rating from './inputs/rating';
import textarea from './inputs/textarea';
import selectGroup from './inputs/selectGroup';

export default {
  global,
  checkbox,
  form,
  radio,
  search,
  tel,
  text,
  textarea,
  counter,
  rating,
  selectGroup,
  'family:box': familyBox,
  'family:text': familyText
};
