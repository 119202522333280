export default {
  outer: '',
  wrapper: '',
  fieldset: '',
  legend: '',
  help: '',
  inner: '',
  options: '',
  option: '',
  decorator: '',
  decoratorIcon: '',
  label: '',
  optionHelp: '',
  messages: '',
  message: '',
  itemsWrapper: 'flex',
  items: 'pr-1.25 last:pr-0 cursor-pointer',
  iconWrapper: 'flex px-12.5px py-6.5px text-7 rounded-3 border-1.5 bg-rating-white text-white',
  icon: 'block text-7',
  iconInactive: 'i-custom:rating-star-inactive',
  iconActive: 'i-custom:rating-star drop-shadow-rating-icon-green',
  iconHover: 'i-custom:rating-star-hover drop-shadow-rating-icon-green-light'
};
