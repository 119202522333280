<template>
  <component
    :is="context.slots.test"
    v-if="context.slots?.test"
    :close
    :open
  ></component>
  <Button
    v-else
    ref="inputEl"
    type="button"
    :class="context.classes.input"
    :ui="{ padding: 'px-3 py-2.25 h-9', rounded: 'rounded-2' }"
    size="xs"
    :variant="context.value ? 'solid' : 'outline'"
    @click="open"
  >
    <!-- <span :class="selectOptionsLabel ? context.classes.selectText : context.classes.placeholder">{{ selectOptionsLabel ? selectOptionsLabel : context.placeholder }}</span>
		<span :class="context.classes.selectIcon"></span> -->
    <template #prefix>
      <span
        v-if="!context.value"
        class="flex items-center justify-center size-4.5 rounded-full bg-firmennyy/15 text-firmennyy text-2.75 -tracking-0.01em font-700"
      >+</span>
    </template>
    {{ context.value ? selectOptionsLabel : 'Ко времени' }}
  </Button>
  <div
    v-show="isShow"
    ref="dropdownWrapperEl"
    :class="context.classes.dropdownWrapper"
  >
    <div :class="context.classes.selectGroupWrapper">
      <div
        v-for="item in context.options"
        :data-selected="selectOptionsGroup?.group === item.group"
        :class="context.classes.selectGroupItem"
        @click="selectOptionsGroup = (item as any)"
      >
        {{ item.group }}
      </div>
    </div>
    <ul
      role="listbox"
      :class="context.classes.listbox"
    >
      <li
        v-for="option in selectOptionsGroup?.options"
        :data-selected="option.value == context.value"
        :class="[context.classes.listItem, { 'pointer-events-none': !isShow }]"
        @click="handleInput(option.value, option.label)"
      >
        {{ option.label }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import type { FormKitFrameworkContext } from '@formkit/core';
interface OptionsType { label: string, value: unknown }
interface OptionsGroupType { group: string, options: OptionsType[] }
const props = defineProps<{
	context: FormKitFrameworkContext & {
		options: OptionsGroupType[],
	}
}>();

const isShow = ref(false);

const close = () => {
  isShow.value = false;
};

const open = () => {
  isShow.value = true;
};

const selectOptionsLabel = ref();

function handleInput (value: unknown, label:string) {
  if (value == props.context.value) { return props.context.node.input(null); }
  selectOptionsLabel.value = label;
  props.context.node.input(value);
  close();
}

const selectOptionsGroup = ref<OptionsGroupType | undefined>(props.context.options.find(() => true) as any);

const inputEl = ref(null);
const dropdownWrapperEl = ref(null);

onClickOutside(inputEl, (event) => {
  if (isShow.value) {
    isShow.value = false;
    props.context.handlers.blur();
  }
}, { ignore: [dropdownWrapperEl] });
</script>
